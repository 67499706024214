<template>
    <div class="flex flex-col h-full">
        <div class="font-frank font-bold text-sm text-black">
            {{ value.length }} {{ $i18n.tc('app.item', value.length) }} selected
        </div>
        <slot />
        <div class="h-full overflow-y-auto mt-3">
            <list
                :columns="columns"
                :items="amenities"
                :css="{ bodyRowClass: 'row' }"
                :has-filters="hasFilters"
            >
                <template
                    #row="{
                        item: {
                            id,
                            name,
                            description,
                            type,
                            discount,
                            discountType,
                            communityDiscount,
                            communityDiscountType,
                            total,
                            itemsAmount,
                            unitsAmount,
                        },
                    }"
                >
                    <collapsible
                        :css="{
                            contentClass: 'pl-20 pr-6 pb-6',
                            headingExpandedClass: 'pb-0',
                        }"
                        class="amenity-selector__item"
                        :class="{ 'amenity-selector__item--unassigned': !isActive(id) && unitsAmount }"
                    >
                        <template #heading>
                            <div class="amenity-selector__item-head">
                                <toggle-input
                                    :model-value="isActive(id)"
                                    size="small"
                                    @update:modelValue="val => handleToggleChange(id, val)"
                                />
                                <div class="amenity-selector__item-title">
                                    <TextHighlighter
                                        :text="name"
                                        :query="query"
                                        words-mode
                                    />
                                    <div
                                        v-if="unitsAmount >= 0"
                                        class="amenity-selector__item-units"
                                    >
                                        <icon
                                            name="warning2"
                                            class="amenity-selector__item-icon"
                                        />
                                        {{ getUnitsLabel(unitsAmount) }} assigned
                                    </div>
                                </div>
                                <amount-input
                                    amount-class="text-sm"
                                    :value="total"
                                    prefix="$"
                                    read-only
                                >
                                    <template #default="{ maskedValue }">
                                        <TextHighlighter
                                            :text="maskedValue"
                                            :query="maskedQuery"
                                            words-mode
                                        />
                                    </template>
                                </amount-input>
                            </div>
                        </template>
                        <template #content>
                            <div>
                                <div
                                    v-if="discount > 0"
                                    class="amenity-selector__discount"
                                >
                                    incl.&#160;
                                    <amount-input
                                        amount-class="mr-1"
                                        :value="discount"
                                        v-bind="getDiscountProps(discountType)"
                                        read-only
                                    />
                                    {{ getDiscountLabel() }}
                                </div>

                                <div
                                    v-if="communityDiscount > 0"
                                    class="amenity-selector__discount"
                                >
                                    incl.&#160;
                                    <amount-input
                                        amount-class="mr-1"
                                        :value="communityDiscount"
                                        v-bind="getDiscountProps(communityDiscountType)"
                                        read-only
                                    />
                                    {{ getDiscountLabel(true) }}
                                </div>

                                <div class="amenity-selector__separator">
                                    <div class="amenity-selector__description">
                                        {{ description }}
                                    </div>
                                    <router-link
                                        v-if="type === 'AMENITY' && amenityLink"
                                        class="amenity-selector__edit-link"
                                        :to="{
                                            name: amenityLink,
                                            params: { amenityId: id },
                                        }"
                                    >
                                        edit amenity
                                    </router-link>
                                    <router-link
                                        v-if="type === 'GROUP' && groupLink"
                                        class="amenity-selector__edit-link"
                                        :to="{
                                            name: groupLink,
                                            params: { groupId: id },
                                        }"
                                    >
                                        edit group
                                    </router-link>
                                </div>

                                <div
                                    v-if="type === 'GROUP'"
                                    class="amenity-selector__separator"
                                >
                                    <collapsible
                                        :css="{
                                            collapsibleClass: '',
                                            expandedClass: '',
                                            headingClass: 'text-blue-500 font-frank font-medium text-2sm',
                                            headingExpandedClass: 'mb-3',
                                            contentClass: '',
                                            arrowClass: 'w-4 min-w-4 h-4 ml-1',
                                        }"
                                        @click="fetchGroupItems(id)"
                                    >
                                        <template #heading>
                                            individual amenities ({{ itemsAmount }})
                                        </template>
                                        <template #content>
                                            <list
                                                :columns="groupColumns"
                                                :items="groupItems[id]"
                                                :css="{
                                                    tableClass: 'table table--striped',
                                                    tableHeaderClass: 'head',
                                                    headRowClass: 'border-b border-black',
                                                    bodyRowClass: 'row',
                                                }"
                                            >
                                                <template #column:amenity.name="{ value }">
                                                    <text-trimmer :text="value" />
                                                </template>

                                                <template #column:amenity.pricingUnit="{ value }">
                                                    {{ getPricingUnitLabel(value) || '-' }}
                                                </template>

                                                <template
                                                    #column:amount="{
                                                        value,
                                                        item: {
                                                            amenity: { pricingUnit },
                                                        },
                                                    }"
                                                >
                                                    <amount-input
                                                        v-if="pricingUnit"
                                                        :value="value"
                                                        :precision="getPrecision(pricingUnit)"
                                                        read-only
                                                    />
                                                    <template v-else>
                                                        -
                                                    </template>
                                                </template>

                                                <template #column:amenity.price="{ value }">
                                                    <amount-input
                                                        :value="value"
                                                        prefix="$"
                                                        read-only
                                                    />
                                                </template>
                                            </list>
                                        </template>
                                    </collapsible>
                                </div>
                            </div>
                        </template>
                    </collapsible>
                </template>
            </list>
        </div>
    </div>
</template>

<script>
import List from '@/components/list/List';
import ToggleInput from '@/components/ui/ToggleInput';
import Collapsible from '@/components/ui/Collapsible';
import AmountInput from '@/components/ui/AmountInput';
import TextTrimmer from '@/components/ui/TextTrimmer';
import TextHighlighter from '@/components/ui/TextHighlighter';
import NotifyMixin from '@/mixins/NotifyMixin';
import AmenityMixin from '@/mixins/AmenityMixin';
import Icon from '@/components/ui/Icon';
import { maskQuery } from '@/utils/Amount';

export default {
    components: {
        List,
        ToggleInput,
        AmountInput,
        Collapsible,
        TextTrimmer,
        Icon,
        TextHighlighter,
    },

    mixins: [NotifyMixin, AmenityMixin],

    props: {
        amenities: {
            type: Array,
            required: true,
        },

        value: {
            type: Array,
            required: true,
        },

        amenityLink: {
            type: [String, null],
            required: true,
        },

        groupLink: {
            type: [String, null],
            required: true,
        },

        groupListResource: {
            type: String,
            required: true,
        },

        level: {
            type: String,
            default: 'ENTERPRISE',
        },

        query: {
            type: String,
            default: '',
        },
    },

    emits: ['input'],

    data: () => {
        return {
            columns: [
                {
                    name: 'toggle',
                    title: 'toggle',
                    class: 'w-20',
                },
                {
                    name: 'amenities',
                    title: 'individual amenities and groups',
                    class: 'flex-grow',
                },
                {
                    name: 'price',
                    title: 'price with discount(s)',
                    class: 'mr-8 text-right',
                },
            ],

            groupColumns: [
                {
                    name: 'amenity.name',
                    title: 'name',
                    class: 'w-5/12',
                },
                {
                    name: 'amenity.pricingUnit',
                    title: 'per',
                    class: 'w-2/12 text-center',
                },
                {
                    name: 'amount',
                    title: 'qty',
                    class: 'w-2/12 text-center',
                },
                {
                    name: 'amenity.price',
                    title: 'price',
                    class: 'w-3/12 text-right',
                },
            ],

            groupItems: {},
        };
    },

    computed: {
        hasFilters() {
            return !!this.query;
        },

        maskedQuery() {
            if (this.query) {
                return maskQuery(this.query);
            }

            return this.query;
        },
    },

    methods: {
        fetchGroupItems(groupId) {
            if (this.groupItems[groupId]) {
                return;
            }

            this.$amntDataProvider
                .getList(this.groupListResource, { groupId })
                .then(
                    groupItems =>
                        (this.groupItems = {
                            ...this.groupItems,
                            [groupId]: groupItems,
                        })
                )
                .catch(error => this.notifyError(error.message));
        },

        handleToggleChange(amenityId, enabled) {
            this.$emit('input', enabled ? [...this.value, amenityId] : this.value.filter(id => id !== amenityId));
        },

        getDiscountLabel(isCommunityDiscount) {
            const levelPrefix = isCommunityDiscount ? 'community ' : this.level === 'COMMUNITY' ? 'enterprise ' : '';

            return `${levelPrefix}discount`;
        },

        isActive(id) {
            return this.value.includes(id);
        },
    },
};
</script>

<style scoped>
.amenity-selector__discount {
    @apply flex justify-end text-xs mt-1 mr-6;
}

.amenity-selector__description {
    color: #2573a7;
    @apply text-sm;
}

.amenity-selector__edit-link {
    @apply inline-block text-blue-500 underline font-frank text-sm mt-2;
}

.amenity-selector__separator {
    border-color: #d1e8f8;
    @apply border-t pt-4 mt-4;
}

.amenity-selector__item-head {
    @apply flex flex-grow items-center pr-4;
}

.amenity-selector__item-title {
    @apply ml-6 flex-grow text-sm;
}

.amenity-selector__item-units {
    @apply font-inter text-graphite-900 text-xs;
}
.amenity-selector__item-icon {
    @apply w-4 h-4 hidden;
}

.amenity-selector__item--unassigned {
    .amenity-selector__item-units {
        @apply text-red-500;
    }

    .amenity-selector__item-icon {
        @apply inline-block;
    }
}
</style>
