<template>
    <AmountInput
        v-if="rowData.amenityId"
        :value="rowData.price"
        prefix="$"
        amount-class="text-black"
        read-only
    />
</template>

<script>
import AmountInput from '@/components/ui/AmountInput';

export default {
    name: 'AmenityPriceField',

    components: {
        AmountInput,
    },

    props: {
        rowData: {
            type: Object,
            required: true,
        },

        rowIndex: {
            type: Number,
            required: true,
        },
    },
};
</script>
