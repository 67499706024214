<template>
  <span class="highlighter" v-html="result"></span>
</template>

<script>
import {
  prepareFullQueryModeHighlighter,
  prepareWordsModeHighlighter,
  prepareWordStartHighlighter
} from "@/utils/highlighting";

export default {
  name: "TextHighlighter",
  props: {
    text: {
      type: String,
      required: true
    },
    query: {
      type: String,
    },
    wordsMode: {
      type: Boolean,
      default: false
    },
    startOnly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    prepareHighlighted() {
      if (this.startOnly) return prepareWordStartHighlighter(this.query);

      return this.wordsMode ? prepareWordsModeHighlighter(this.query) : prepareFullQueryModeHighlighter(this.query);
    },
    result() {
      if (this.query) {
        return this.prepareHighlighted(this.text);
      }
      return this.text;
    },
  },
}
</script>

<style scoped>
.highlighter:deep(.highlighted) {
  background-color: yellow;
}
</style>
