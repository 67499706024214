import { DISCOUNT_TYPES } from '@/components/amenities/constants';
import { round } from '@/utils/Numbers';

export default {
    data() {
        return {
            precision: 2,
        };
    },
    methods: {
        getGroupsLabel(groups) {
            return `${groups} ${this.$i18n.tc('app.group', groups)}`;
        },
        getCommunitiesLabel(communities) {
            return `${communities} ${this.$i18n.tc('app.community', communities)}`;
        },
        getUnitsLabel(units) {
            return `${units} ${this.$i18n.tc('app.unit', units)}`;
        },
        getAssignmentsLabels({ communities, unitsWithinGroups, unitsIndividually, units }, groups = []) {
            let labels = [];
            const groupsCount = Array.isArray(groups) ? groups.length : groups;

            if (communities != null) {
                labels.push(`${communities} ${this.$i18n.tc('app.community', communities)}`);
            }

            if (unitsWithinGroups != null) {
                labels.push(
                    `${unitsWithinGroups} ${this.$i18n.tc('app.unit', unitsWithinGroups)} (in ${this.$i18n.tc('app.group', groupsCount)})`
                );
            }

            if (unitsIndividually != null) {
                labels.push(`${unitsIndividually} ${this.$i18n.tc('app.unit', unitsIndividually)} (as individual)`);
            }

            if (units != null) {
                labels.push(`${units} ${this.$i18n.tc('app.unit', units)}`);
            }

            if (labels.length > 0) {
                return labels;
            }

            return '0';
        },
        assignmentsEmpty({ communities, unitsWithinGroups, unitsIndividually, units }) {
            return !communities && !unitsWithinGroups && !unitsIndividually && !units;
        },
        getPricingUnitLabel(pricingUnit) {
            switch (pricingUnit) {
            case 'FOOT':
                return 'Sq Foot';
            case 'ITEMS':
                return 'Item';
            case 'ROOMS':
                return 'Room';
            default:
                return '';
            }
        },
        calculatePrice(price, discount = 0, discountType) {
            if (price === 0) return price;

            if (discountType === DISCOUNT_TYPES.PERCENT) {
                return round(price * (1 - discount / 100), this.precision);
            }

            return round(price - discount, this.precision);
        },
        getTotal(price, discount1Data, discount2Data) {
            return (
                this.calculatePrice(
                    this.calculatePrice(price, discount1Data.discount, discount1Data.type),
                    discount2Data?.discount,
                    discount2Data?.type
                ) || 0
            );
        },
        getPrecision(pricingUnit) {
            switch (pricingUnit) {
            case 'ROOMS':
                return 0;
            case 'ITEMS':
                return 1;
            case 'FOOT':
            default:
                return 2;
            }
        },
        getDiscountProps(discountType) {
            if (discountType === DISCOUNT_TYPES.PERCENT) {
                return {
                    suffix: '%',
                    precision: 0,
                    max: 100,
                    min: 0,
                };
            }

            return {
                prefix: '$',
                min: 0,
            };
        },
    },
};
