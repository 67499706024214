<template>
    <AmountField
        v-if="rowData.pricingUnit"
        :key="rowData.pricingUnit"
        :name="`groupAmenities[${rowIndex}].amount`"
        text-class=""
        :edit-mode="editMode"
        v-bind="validationProps"
        :compute-width="false"
    />
    <div
        v-else
        class="no-quantity"
    >
        Does not depend on quantity
    </div>
</template>

<script>
import AmountField from '@/components/form/AmountField';
import ValidatorMixin from '@/components/form/ValidatorMixin';

export default {
    name: 'AmenityAmountField',
    components: {
        AmountField,
    },

    mixins: [ValidatorMixin],

    props: {
        editMode: {
            type: Boolean,
            default: true,
        },

        rowData: {
            type: Object,
            required: true,
        },

        rowIndex: {
            type: Number,
            required: true,
        },
    },

    computed: {
        validationProps() {
            const { pricingUnit } = this.rowData;

            switch (pricingUnit) {
            case 'FOOT':
                return {
                    max: 9999999999999.99,
                    validate: this.minValue(0.01),
                };
            case 'ITEMS':
                return {
                    max: 99999999999999.9,
                    precision: 1,
                    validate: this.minValue(0.1),
                };
            case 'ROOMS':
                return {
                    max: 999,
                    precision: 0,
                    validate: this.minValue(1),
                };
            default:
                return {};
            }
        },
    },
};
</script>

<style scoped>
.no-quantity {
    color: #b2b2b2;
    font-size: 0.8125rem;
    @apply font-inter;
}
</style>
