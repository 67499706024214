<template>
  <div class="collapsible" :class="[ finalCss.collapsibleClass, expanded ? finalCss.expandedClass : '', { 'collapsible--expanded': expanded } ]">
    <div class="collapsible__heading" :class="[ finalCss.headingClass, expanded ? finalCss.headingExpandedClass : '' ]" @click="handleClick">
      <!-- @slot Heading block -->
      <slot name="heading" />
      <icon name="chevronDown" class="collapsible__arrow" :class="finalCss.arrowClass" />
    </div>
    <div
      v-if="expanded"
      class="collapsible__content"
      :class="finalCss.contentClass"
    >
      <!-- @slot Content block -->
      <slot name="content" />
    </div>
  </div>
</template>

<script>
import Icon from "@/components/ui/Icon";

const css = {
  collapsibleClass: 'w-full border border-transparent hover:bg-blue-200',
  expandedClass: 'bg-blue-200',
  headingClass: 'p-4',
  headingExpandedClass: '',
  contentClass: 'px-4 pb-4',
  arrowClass: 'w-4 min-w-4 h-4 text-gray-300',
};

export default {
  name: 'Collapsible',

  components: {
      Icon,
  },

  props: {
    /**
     * Initial value for the `expanded` data prop
     */
    initialExpanded: {
      type: Boolean,
      default: false,
    },
    /**
     * Custom CSS styles
     */
    css: {
      type: Object,
      default() {
        return css;
      },
    },
  },

  emits: ['click'],

  methods: {
    handleClick(){
      this.expanded = !this.expanded;
      /**
       * Emitted when the heading panel was clicked
       */
      this.$emit('click');
    },
  },

  data(){
    return {
      expanded: false,
    };
  },

  watch: {
    initialExpanded: {
      handler: function() {
        this.expanded = this.initialExpanded;
      },
      immediate: true,
    }
  },

  computed: {
    finalCss() {
      return { ...css, ...this.css };
    },
  }
}
</script>

<style scoped>
  .collapsible--expanded {
    border-color: #D1E8F8;

    & > .collapsible__heading .collapsible__arrow {
      transform: rotate(180deg);
    }
  }

  .collapsible__heading {
    @apply flex items-center cursor-pointer;
  }
</style>
