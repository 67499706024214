<template>
  <BeatLoader v-if="status === ASSIGNMENT_STATUSES.IN_PROGRESS" class="transform transform-origin-0 scale-50"/>
  <CheckmarkIcon v-else-if="status === ASSIGNMENT_STATUSES.SUCCESS" class="w-3 h-3 text-green-500"/>
  <Close2Icon v-else-if="status === ASSIGNMENT_STATUSES.FAILED" class="w-3 h-3 text-red-500"/>
</template>

<script>
import BeatLoader from "@/components/ui/BeatLoader";
import CheckmarkIcon from "@/components/ui/icons/CheckmarkIcon";
import Close2Icon from "@/components/ui/icons/Close2Icon";

export const ASSIGNMENT_STATUSES = {
  IN_PROGRESS: 'in-progress',
  SUCCESS: 'success',
  FAILED: 'failed',
};

export default {
  components: {Close2Icon, CheckmarkIcon, BeatLoader},

  props: {
    status: {
      type: String,
      validation: function(value) {
        return [
          ASSIGNMENT_STATUSES.IN_PROGRESS,
          ASSIGNMENT_STATUSES.SUCCESS,
          ASSIGNMENT_STATUSES.FAILED,
          null,
        ].indexOf(value) !== -1;
      },
    },
  },

  created(){
    this.ASSIGNMENT_STATUSES = ASSIGNMENT_STATUSES;
  }
}
</script>

<style scoped>
.transform-origin-0 {
  transform-origin: 0;
}
</style>
