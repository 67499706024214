<template>
  <FinalField :name="name" :validate="validate">
    <template v-slot="props">
      <div class="form-col">
        <ToggleButtonsInput
          :value="props.value"
          v-bind="$attrs"
          @change="val => props.change(val)"
        />

        <FieldError v-if="!suppressError" :name="name" />
      </div>
    </template>
  </FinalField>
</template>

<script>
import {FinalField} from 'vue-final-form';
import ToggleButtonsInput from '@/components/ui/ToggleButtonsInput';
import FieldError from "@/components/form/FieldError";

export default {
  name: "ToggleButtonsField",

  components: {
    FinalField,
    ToggleButtonsInput,
    FieldError
  },

  props: {
    name: {
      type: String,
      required: true,
    },
  },
}
</script>
