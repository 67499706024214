<template>
    <div class="total-panel">
        <div
            v-if="assignments"
            class="form-col form-col--assigned-communities"
        >
            <label>assigned to</label>
            <span
                v-if="assignmentsEmpty(assignments)"
                class="not-specified"
            >Not specified</span>
            <router-link
                v-else-if="communityAssignmentRouteData"
                class="inline-flex flex-col assignment-link underline"
                :to="communityAssignmentRouteData"
            >
                <span
                    v-for="(label, index) in assignmentLabels"
                    :key="index"
                    class="whitespace-no-wrap"
                >
                    <!-- eslint-disable-next-line vue/no-parsing-error -->
                    {{ label }}{{ index + 1 < assignmentLabels.length ? ',' : '' }}
                </span>
            </router-link>
            <span
                v-else
                class="inline-flex flex-col text-2sm"
            >
                <span
                    v-for="(label, index) in assignmentLabels"
                    :key="index"
                    class="whitespace-no-wrap"
                >
                    <!-- eslint-disable-next-line vue/no-parsing-error -->
                    {{ label }}{{ index + 1 < assignmentLabels.length ? ',' : '' }}
                </span>
            </span>
        </div>

        <div
            v-if="hasGroups"
            class="form-col form-col--groups"
        >
            <label>group membership</label>
            <span
                v-if="groups === 0"
                class="not-specified"
            >Not specified</span>
            <router-link
                v-else-if="hasGroupMembershipAccess"
                class="assignment-link underline whitespace-no-wrap"
                :to="groupMembershipRouteData"
            >
                {{ getGroupsLabel(groups) }}
            </router-link>
            <router-link
                v-else-if="!isEnterpriseLevel && hasPermission('CL_AGV')"
                class="assignment-link underline whitespace-no-wrap"
                :to="{ name: 'amenities.community.individual.groupMembership' }"
            >
                {{ getGroupsLabel(groups) }}
            </router-link>
            <span
                v-else
                class="text-2sm whitespace-no-wrap"
            >
                {{ getGroupsLabel(groups) }}
            </span>
        </div>

        <div class="col-separator">
            <div
                v-if="hasGroups"
                class="border-l border-gray-200"
            />
        </div>

        <AmountField
            v-if="isEnterpriseLevel"
            name="price"
            :label="enterprisePriceLabel"
            :edit-mode="editMode && isEnterpriseIndividual"
            prefix="$"
            class="form-col--price"
            v-bind="totalInputProps"
        />

        <AmountField
            v-if="!isEnterpriseLevel"
            name="globalPrice"
            :label="basePriceLabel"
            prefix="$"
            :max="maxPrice"
            :min="-maxPrice"
            allow-negative
            :edit-mode="false"
            class="form-col--price"
        />

        <AmountField
            v-if="!isEnterpriseLevel"
            name="globalDiscount"
            label="global discount"
            :edit-mode="false"
            class="form-col--discount"
            v-bind="getDiscountProps(values.globalDiscountType)"
        />

        <AmountField
            name="discount"
            :label="discountLabel"
            :edit-mode="editMode"
            :disabled="discountDisabled"
            :min-size="getMinSize(values.price || values.globalPrice)"
            v-bind="{ ...discountValidationProps, ...getDiscountProps(values.discountType) }"
            class="form-col--discount"
            input-class="flex justify-end"
        >
            <template #before>
                <ToggleButtonsField
                    v-if="editMode"
                    name="discountType"
                    size="large"
                    button-class="px-0 w-10"
                    class="mr-1"
                    :options="discountTypeOptions"
                    :disabled="discountDisabled"
                />
            </template>
        </AmountField>

        <AmountField
            name="total"
            :label="totalLabel"
            :edit-mode="false"
            prefix="$"
            text-class="font-bold"
            class="form-col--total"
        >
            <template #after>
                <span
                    v-if="pricingUnitLabel"
                    class="font-bold"
                >{{ pricingUnitLabel }}</span>
            </template>
        </AmountField>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AmountField from '@/components/form/AmountField';
import ValidatorMixin from '@/components/form/ValidatorMixin';
import AmenityMixin from '@/mixins/AmenityMixin';
import ToggleButtonsField from '@/components/form/ToggleButtonsField';
import { DISCOUNT_TYPES } from '@/components/amenities/constants';
import { mask } from '@/utils/Amount';

const MAX_PRICE = 999999.99;

export default {
    components: {
        AmountField,
        ToggleButtonsField,
    },

    mixins: [ValidatorMixin, AmenityMixin],

    props: {
        editMode: {
            type: Boolean,
            required: true,
        },

        isGroup: {
            type: Boolean,
            default: false,
        },

        level: {
            type: String,
            default: 'ENTERPRISE',
            validator: function (value) {
                return ['ENTERPRISE', 'COMMUNITY', 'LOCATION'].indexOf(value) !== -1;
            },
        },

        values: {
            type: Object,
            required: true,
        },
    },

    computed: {
        ...mapGetters({
            hasPermission: 'amnt/hasPermission',
        }),

        discountDisabled() {
            return this.values.price < 0 || this.values.globalPrice < 0 || this.emptyGlobalTotal;
        },

        emptyGlobalTotal() {
            return (
                !this.isEnterpriseLevel &&
                this.calculatePrice(this.values.globalPrice, this.values.globalDiscount, this.values.globalDiscountType) === 0
            );
        },

        discountTypeOptions() {
            return [
                { key: DISCOUNT_TYPES.PERCENT, value: '%' },
                { key: DISCOUNT_TYPES.SIMPLE, value: '$' },
            ];
        },

        discountValidationProps() {
            if (this.values.discountType === DISCOUNT_TYPES.PERCENT) {
                return {
                    validate: this.composeValidators(this.maxLength(4)),
                };
            }

            return {
                max: this.maxDiscount,
            };
        },

        maxDiscount() {
            if (this.discountDisabled) {
                return 0;
            }

            return this.isEnterpriseLevel
                ? this.calculatePrice(this.values.price)
                : this.calculatePrice(this.values.globalPrice, this.values.globalDiscount, this.values.globalDiscountType);
        },

        assignmentLabels() {
            const assignmentLabels = this.getAssignmentsLabels(this.assignments, this.groups);

            return Array.isArray(assignmentLabels) ? assignmentLabels : [assignmentLabels];
        },

        groups() {
            return this.values.groups;
        },

        assignments() {
            return this.values.assignments;
        },

        hasGroups() {
            return typeof this.groups === 'number';
        },

        priceLabel() {
            if (this.isEnterpriseLevel) {
                if (this.isGroup) {
                    return 'group price';
                }

                if (this.editMode) {
                    return 'price';
                }
            }

            return 'global price';
        },

        pricingUnitLabel() {
            let label = '';
            const pricing = this.values.pricingUnit;

            if (pricing) {
                switch (pricing) {
                case 'FOOT':
                    label = ` per 1 sq ft`;
                    break;
                default:
                    label = ` per ${this.getPricingUnitLabel(pricing).toLowerCase()}`;
                }
            }

            return label;
        },

        basePriceLabel() {
            return this.priceLabel + this.pricingUnitLabel;
        },

        enterprisePriceLabel() {
            return this.basePriceLabel + `${this.editMode && !this.isGroup ? '*' : ''}`;
        },

        discountLabel() {
            return this.isEnterpriseLevel ? 'global discount' : 'community discount';
        },

        totalLabel() {
            return this.isEnterpriseLevel ? 'total with discount' : 'total with discount(s)';
        },

        totalInputProps() {
            if (this.isEnterpriseIndividual) {
                return {
                    validate: this.composeValidators(this.amountNotEmpty(this.precision), this.maxLength(13)),
                    max: 999999.99,
                    min: -999999.99,
                    allowNegative: true,
                };
            }

            return {};
        },

        communityAssignmentRouteData() {
            if (this.isGroup && this.isEnterpriseLevel) {
                if (!this.hasPermission('EL_AGV')) {
                    return null;
                }

                return {
                    name: 'amenities.enterprise.groups.assignedCommunities',
                    params: { groupId: this.$route.params.groupId },
                };
            }

            if (this.isGroup && !this.isEnterpriseLevel) {
                if (!this.hasPermission('CL_AGV')) {
                    return null;
                }

                return {
                    name: 'amenities.community.groups.assignedCommunities',
                    params: { groupId: this.$route.params.groupId },
                };
            }

            if (!this.isGroup && this.isEnterpriseLevel) {
                if (!this.hasPermission('EL_AV')) {
                    return null;
                }

                return {
                    name: 'amenities.enterprise.individual.assignedCommunities',
                    params: { amenityId: this.$route.params.amenityId },
                };
            }

            if (!this.isGroup && !this.isEnterpriseLevel) {
                if (!this.hasPermission('CL_AV')) {
                    return null;
                }

                return {
                    name: 'amenities.community.individual.assignedCommunities',
                    params: { amenityId: this.$route.params.amenityId },
                };
            }

            return null;
        },

        groupMembershipRouteData() {
            return {
                name: this.isEnterpriseLevel
                    ? 'amenities.enterprise.individual.groupMembership'
                    : 'amenities.community.individual.groupMembership',
            };
        },

        isEnterpriseLevel() {
            return this.level === 'ENTERPRISE';
        },

        isEnterpriseIndividual() {
            return this.isEnterpriseLevel && !this.isGroup;
        },

        hasGroupMembershipAccess() {
            return this.hasPermission('EL_AGV') || this.hasPermission('CL_AGV');
        },

        maxPrice() {
            return MAX_PRICE;
        },
    },

    methods: {
        mask,

        getMinSize(price) {
            const minPrice = price ? Math.max(this.maxPrice, price) : this.maxPrice;

            return mask(minPrice, { prefix: '$' }).length;
        },
    },
};
</script>

<style lang="postcss" scoped>
.total-panel {
    @apply flex flex-row flex-shrink-0 justify-between overflow-y-auto p-5 bg-gray-50 border border-gray-300;

    :deep(label) {
        @apply text-xs;
    }
}

.form-col {
    @apply m-0;
    flex: 0 1 0;

    &--groups,
    &--discount,
    &--total {
        @apply ml-5;
    }

    &--price,
    &--discount,
    &--total {
        @apply flex flex-col text-right;
    }

    :deep(label) {
        @apply whitespace-no-wrap;
    }
}

.col-separator {
    @apply flex justify-center;
    flex: 1 0 3rem;
}

.not-specified {
    @apply font-inter text-gray-400 whitespace-no-wrap;
}
</style>
